import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  class: "searchbar",
  action: ""
}
const _hoisted_2 = { style: {"padding":"100px 0"} }
const _hoisted_3 = { class: "process-comps-approvedItem" }
const _hoisted_4 = ["onTouchend"]
const _hoisted_5 = { class: "process-comps-approvedItem-main" }
const _hoisted_6 = { class: "process-comps-approvedItem-title" }
const _hoisted_7 = { class: "process-comps-approvedItem-status" }
const _hoisted_8 = { class: "process-comps-approvedItem-description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_TaskFilter = _resolveComponent("TaskFilter")!
  const _component_van_search = _resolveComponent("van-search")!
  const _component_Empty = _resolveComponent("Empty")!
  const _component_van_cell = _resolveComponent("van-cell")!
  const _component_van_list = _resolveComponent("van-list")!
  const _component_van_pull_refresh = _resolveComponent("van-pull-refresh")!

  return (_openBlock(), _createElementBlock("div", {
    class: "history-container",
    style: _normalizeStyle({
      paddingTop: _ctx.paddingTop + 'px',
    })
  }, [
    _createElementVNode("form", _hoisted_1, [
      _createVNode(_component_PageHeader, { title: "审批历史" }),
      _createVNode(_component_van_search, {
        modelValue: _ctx.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
        placeholder: "请输入姓名、主题或流水单号",
        "show-action": "",
        onSearch: _ctx.onSearch
      }, {
        action: _withCtx(() => [
          _createVNode(_component_TaskFilter, {
            defaultTime: "2",
            onFilter: _ctx.onFilter,
            hideState: ""
          }, null, 8, ["onFilter"])
        ]),
        _: 1
      }, 8, ["modelValue", "onSearch"])
    ]),
    _createVNode(_component_van_pull_refresh, {
      modelValue: _ctx.refreshing,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.refreshing) = $event)),
      onRefresh: _ctx.onRefresh,
      style: _normalizeStyle({
        display: 'block',
        height: `calc(100vh - ${_ctx.paddingTop + 'px'})`,
        overflow: 'overlay',
      })
    }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Empty, { content: _ctx.emptyText }, null, 8, ["content"])
        ], 512), [
          [_vShow, !_ctx.loading && _ctx.list.length === 0]
        ]),
        _createVNode(_component_van_list, {
          loading: _ctx.loading,
          "onUpdate:loading": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.loading) = $event)),
          finished: _ctx.finished,
          offset: "100",
          "immediate-check": false,
          "finished-text": _ctx.list.length > 0 ? '没有更多了' : '',
          onLoad: _ctx.onLoad
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
              return (_openBlock(), _createBlock(_component_van_cell, { key: index }, {
                value: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", {
                      class: "process-comps-approvedItem-content",
                      onTouchstart: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.touchstart && _ctx.touchstart(...args))),
                      onTouchmove: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.touchmove && _ctx.touchmove(...args))),
                      onTouchend: () => _ctx.touchend(item)
                    }, [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, _toDisplayString(item.processInstanceName), 1),
                        _createElementVNode("div", _hoisted_7, _toDisplayString(item.status === "4" || item.status === "5"
                        ? "办结"
                        : "已办"), 1)
                      ]),
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("span", null, _toDisplayString(item.realName) + "(" + _toDisplayString(item.organizationName) + ")", 1),
                        _createElementVNode("span", null, _toDisplayString(item.beginTime), 1)
                      ])
                    ], 40, _hoisted_4)
                  ])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }, 8, ["loading", "finished", "finished-text", "onLoad"])
      ]),
      _: 1
    }, 8, ["modelValue", "onRefresh", "style"])
  ], 4))
}