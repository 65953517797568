
import { defineComponent, nextTick, ref, computed } from "vue";
import { useRouter } from "vue-router";
import fetchApi from "@/api";
import { eventBus, getDeviceSomeInfo } from "@/utils";
import { ApprovedListItemProps } from "./../type";
import { Toast } from "vant";
import TaskFilter from "@/components/TaskFIlter.vue";
import { SearchProps } from "../myApply/type";
import PageHeader from "@/components/PageHeader.vue";


interface SearchValProps {
  endTimeStart: string;
  endTimeEnd: string;
}

export default defineComponent({
  name: "ApproveHistory",
  components: { TaskFilter, PageHeader },
  setup() {
    const { safeTop } = getDeviceSomeInfo();
    const paddingTop = Math.max(safeTop - 46, 0) + 44 + 54;
    const router = useRouter();
    const value = ref("");
    const list = ref<ApprovedListItemProps[]>([]);
    const pageIndex = ref(1);
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);
    const flag = ref(1);
    const searchVal = ref<SearchValProps>({
      endTimeStart: "",
      endTimeEnd: "",
    });
    const timeStatus = ref("0");

    const emptyText = computed(() => {
      if (timeStatus.value === "1") {
        return "当前一周内没有数据";
      }
      if (timeStatus.value === "2") {
        return "当前1个月内没有数据";
      }
      if (timeStatus.value === "3") {
        return "当前3个月内没有数据";
      }
      if (timeStatus.value === "0") {
        return "当前筛选时间内没有数据";
      }
      return "没有符合条件的结果";
    });

    const onLoad = () => {
      if (refreshing.value) {
        list.value = [];
        refreshing.value = false;
        pageIndex.value = 1;
      }
      return fetchApi
        .getApprovedList()
        .data({
          pageNo: pageIndex.value,
          pageSize: 20,
          keyword: value.value,
          ...searchVal.value,
        })
        .send("POST")
        .then((res) => {
          if (res.data) {
            const data = JSON.parse(res.data);
            console.log(data);
            list.value = [...list.value, ...data.result];
            if (
              data.totalCount <= list.value.length ||
              data.result.length === 0
            ) {
              finished.value = true;
            }
            pageIndex.value++;
            loading.value = false;
          }
        })
        .catch(() => {
          loading.value = false;
          finished.value = true;
        });
    };
    const onRefresh = () => {
      if (loading.value) {
        refreshing.value = false;
        return;
      }
      // 清空列表数据
      finished.value = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      loading.value = true;
      onLoad();
    };
    const touchstart = () => {
      flag.value = 1;
    };
    const touchmove = () => {
      flag.value = 0;
    };
    const touchend = (item: ApprovedListItemProps) => {
      if (flag.value === 1) {
        const deepLink = item.meViewUrl;
        if (deepLink) {
          if (deepLink.substring(0, 7) !== "jdme://") {
            Toast.fail("链接格式错误");
            return;
          }
          window.open(deepLink);
        } else {
          router.push({
            path: "/detail",
            query: { processInstanceId: item.processInstanceId },
          });
        }
      }
    };
    const onSearch = () => {
      refreshing.value = true;
      onRefresh();
    };

    eventBus.on("resetHistoryList", () => {
      value.value = "";
      onSearch();
    });

    const onFilter = (val: SearchProps, status: string) => {
      const searchValue: SearchValProps = {
        endTimeStart: val.queryStartDate,
        endTimeEnd: val.queryEndDate,
      };
      searchVal.value = searchValue;
      timeStatus.value = status;
      value.value = "";
      nextTick(() => {
        onSearch();
      });
    };

    return {
      value,
      list,
      refreshing,
      loading,
      finished,
      emptyText,
      onLoad,
      onRefresh,
      touchstart,
      touchmove,
      touchend,
      onSearch,
      onFilter,
      paddingTop,
    };
  },
});
